<template>
  <div class="g_page_box">
    <div class="g_main_content" ref="main" @scroll="scroll_to" v-if="data">
      <g-loading :loading="loading" />
      <div class="order_head">
        <div class="title_box">
          <div class="order_title">
            {{ length === 1 ? data.items[0].title : "确认订单" }}
          </div>
        </div>
        <p>请核实您的检验检查订单</p>
      </div>
      <div
        class="order_img_box"
        ref="img_box"
        v-if="length === 1 && data.items[0].avatar_url"
      >
        <img :src="data.items[0].avatar_url" width="100%" alt="" />
      </div>
      <div class="order_content_box">
        <div class="order_content_list">
          <div
            @click="item_click(item, index)"
            :class="[
              'order_content_item',
              { active: index === item_active, no_click: item.no_click },
            ]"
            v-for="(item, index) in list_title"
            :key="index"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="order_content">
          <div class="order_content_inner" ref="main_out">
            <div class="order_program" ref="program">
              <div class="position_point" ref="program_point"></div>
              <div v-for="(item, index) in data.items" :key="index">
                <div class="title">
                  <span>{{
                    length === 1 && index == 0 ? "项目清单" : item.title
                  }}</span>
                </div>
                <div v-if="item.sub_items.length">
                  <div
                    class="sub_list"
                    v-for="(it, idx) in item.sub_items"
                    :key="idx"
                    @click="show_faq(it)"
                  >
                    <div class="sub_title">
                      {{ it.title }}
                    </div>
                    <div>
                      {{ it.desc }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="order_sense" ref="sence" v-if="clinical.length">
              <div class="position_point" ref="sence_point"></div>
              <div class="title">临床意义</div>
              <div class="order_desc" v-for="(i, d) in unique(clinical, 'answer')" :key="d">
                {{ i.answer }}<br /><br />
              </div>
            </div>
            <div class="order_notify" ref="notify" v-if="notes.length">
              <div class="position_point" ref="notify_point"></div>
              <div class="title">检查须知</div>
              <div class="order_desc" v-for="(s, t) in unique(notes, 'answer')" :key="t">
                {{ s.answer }}<br /><br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pay_box">
        <div class="pay_out">
          <div class="pay_left">
            <div class="pay_price">
              ¥
              <p>{{ format_money(data.order_price) }}</p>
            </div>
            <div class="pay_tips">需支付金额</div>
          </div>
          <div class="pay_btn" @click="jump_to">
            {{ forbidden_list.length || anesthesia_estimate ? "预约" : "支付" }}
          </div>
        </div>
      </div>
      <van-popup
        v-if="faq_item"
        v-model="show"
        closeable
        position="bottom"
        :style="{ minHeight: '30%', maxHeight: '80%' }"
      >
        <div class="pop_head">
          <div class="pop_head_title">
            {{ faq_item.title }}
          </div>
        </div>
        <div class="pop_content">
          <div v-if="faq_item.list.length">
            <div v-for="(item, index) in unique(faq_item.list, 'title')" :key="index">
              <div class="pop_title">
                {{ item.title }}
              </div>
              <div class="pop_text" v-html="item.answer"></div>
            </div>
          </div>
          <div v-else class="pop_text">暂无数据</div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { format_money } from "../../utils/format";
import { order_from_id, get_text, get_question, pay_order } from "./service";

export default {
  data() {
    return {
      data: {},
      list_title: [
        {
          title: "项目清单",
          no_click: false,
        },
        {
          title: "临床意义",
          no_click: false,
        },
        {
          title: "检查须知",
          no_click: false,
        },
      ], //
      loading: false,
      order_id: this.$route.query.order_id,
      item_active: 0,
      element: [],
      point_element: [],
      timer: null,
      show: false,
      faq_item: {
        title: "",
        list: [],
      },
      length: 0,
      other_info: null,
      clinical: [], //临床意义
      notes: [], //检查须知
      forbidden_list: [],
      anesthesia_estimate: this.$route.query.anesthesia_estimate === "true",
    };
  },
  computed: {},
  components: {},
  watch: {},
  created() {
    this.get_list();
    if (!this.anesthesia_estimate) {
      this.get_forbbiden();
    }
  },
  destroyed() {},
  methods: {
    format_money,
    unique(arr, key) {
      const map = new Map();
      return arr.filter(
        (item) => !map.has(item[key] + "") && map.set(item[key] + "", 1)
      );
    },
    lite_unique(arr) {
      return [...new Set(arr)];
    },
    get_element() {
      this.$nextTick(() => {
        this.element = [
          this.$refs.program,
          this.$refs.sence,
          this.$refs.notify,
        ];
        this.point_element = [
          this.$refs.program_point,
          this.$refs.sence_point,
          this.$refs.notify_point,
        ];
      });
    },
    async get_forbbiden() {
      this.loading = true;
      try {
        const { data } = await get_question({ order_id: this.order_id });
        this.forbidden_list = data.forbidden_list;
      } finally {
        this.loading = false;
      }
    },
    jump_to() {
      if (this.anesthesia_estimate || this.forbidden_list.length) {
        this.$router.push({
          path: "contraindication-evaluation",
          query: {
            id: this.order_id,
            anesthesia_estimate: this.anesthesia_estimate,
          },
        });
      } else {
        this.confirm_click();
      }
    },
    async confirm_click() {
      this.loading = true;
      try {
        const { data } = await pay_order({order_id: this.order_id});
        window.open(data.content.payUrl, "_blank");
      } finally {
        this.loading = false;
      }
    },
    async get_list() {
      this.loading = true;
      try {
        const { data } = await order_from_id({order_id: this.order_id});
        this.data = data;
        let codes = [];
        this.data.items.forEach((item) => {
          item.sub_items.forEach((it) => {
            codes.push(it.code);
          });
        });

        if (codes.length) {
          this.get_other(
            {
              ids: [...codes],
              categories: ["检查意义", "临床意义"],
            },
            this.clinical
          );
          this.get_other(
            {
              ids: [...codes],
              categories: ["注意事项", "检查前须知"],
            },
            this.notes
          );
        }
        this.length = parseInt(this.$route.query.length) || 1;
      } finally {
        this.loading = false;
      }
    },
    async get_other(prama, text, clicked) {
      this.loading = true;
      try {
        const { data } = await get_text(prama);
        for (let i in data) {
          let item = data[i];
          item.forEach((it) => {
            text.push({
              title: it.question,
              answer: it.content,
            });
          });
        }
        this.list_title[1].no_click = this.clinical.length === 0;
        this.list_title[2].no_click = this.notes.length === 0;
      } finally {
        this.loading = false;
        if (!clicked) {
          this.get_element();
        }
      }
    },
    item_click(item, index) {
      if (item.no_click) {
        return;
      }
      this.item_active = index;
      this.point_element[index].scrollIntoView({
        behavior: "smooth",
      });
    },
    scroll_to() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        let main = this.$refs.main;
        let recent_top = main.scrollTop;
        let scrollItems = this.element;
        if (recent_top === 0) {
          this.item_active = 0;
          return;
        }
        if (recent_top + main.clientHeight === main.scrollHeight) {
          let i = this.notes.length ? 2 : this.clinical.length ? 1 : 0;
          this.item_active = i;
          return;
        }
        for (let i = scrollItems.length - 1; i >= 0; i--) {
          if (recent_top >= scrollItems[i].offsetTop - 60) {
            this.item_active = i;
            break;
          }
        }
      }, 50);
    },
    show_faq(item) {
      if (!item.code) {
        return;
      }
      this.faq_item.title = item.title;
      this.faq_item.list = [];
      this.get_other(
        {
          ids: [item.code],
        },
        this.faq_item.list,
        true
      );
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>

<style scoped >
.order_head {
  padding: 31px 17px 27px;
  background: url("../../assets/img/family-bg.png") right center no-repeat
    #000ca8;
  background-size: contain;
  color: #ffffff;
  font-size: 12px;
  line-height: 17px;
}

.title_box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
}

.order_title {
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  color: #ffffff;
}

.pay_box {
  position: fixed;
  width: 100%;
  z-index: 1;
  height: 54px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  justify-content: space-between;
}

.pay_out {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.pay_price {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 19px;
  color: #ff6600;

  & p {
    font-size: 19px;
    font-weight: 600;
    padding-left: 2px;
  }
}

.pay_tips {
  font-size: 10px;
  line-height: 14px;
  color: #707070;
}

.pay_btn {
  width: 100px;
  height: 32px;
  background: #0088ff;
  border-radius: 21px;
  font-size: 14px;
  line-height: 32px;
  color: #ffffff;
  text-align: center;
}

.g_main_content {
  background: #f7f7f7;
}

.order_content_box {
  padding-bottom: 54px;
}

.order_content_list {
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #0088ff;
  padding: 0 34px;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
}

.order_content_item {
  display: flex;
  align-items: center;
  padding: 20px 0 10px;

  &.active {
    border-bottom: solid 2px #0088ff;
  }

  &.no_click {
    color: rgba(0, 0, 0, 0.14);
  }
}

.order_content {
  padding-bottom: 20px;
  background: #f7f7f7;
}

.order_content_inner {
  padding: 0 17px;
  background: #fff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
}

.title {
  font-size: 18px;
  font-weight: 500;
  color: #565656;
  position: relative;
  padding-left: 13px;
  line-height: 54px;
  display: flex;
  align-items: center;

  & span {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 8px;
    height: 20px;
    border-radius: 4px;
    background: linear-gradient(180deg, #a5d5ff 0%, #0088ff 100%);
  }
}

.sub_list {
  padding: 14px 17px;
  background: #f4f9fe;
  font-size: 10px;
  line-height: 14px;
  color: #454545;
  margin-bottom: 12px;
  border-radius: 10px;
}

.sub_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #242938;
}

.order_desc {
  font-size: 14px;
  line-height: 20px;
  color: #707070;
}

.order_program,
.order_sense,
.order_notify {
  padding-bottom: 20px;
  position: relative;
}

.position_point {
  position: absolute;
  top: -60px;
}

.pop_head {
  height: 54px;
  line-height: 54px;
  background: url("../../assets/img/family-bg.png") right center no-repeat
    #000ca8;
  background-size: contain;
  padding-left: 17px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}

.pop_head_title {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

>>> .van-popup {
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

>>> .van-popup__close-icon {
  color: #fff;
}

.pop_content {
  padding: 10px 17px 16px;
  font-size: 14px;
  line-height: 19px;
  color: #454545;
  flex: 1;
  overflow-y: auto;
}

.pop_title {
  font-size: 17px;
  font-weight: 500;
  color: #242938;
  padding: 12px 0;
}

.pop_text {
  padding-bottom: 10px;
}

.order_img_box {
  padding-bottom: 20px;
}
</style>
