import api from '../../utils/api'

// 授权
export async function order_from_id (payload) {
  return api.get(`/api/v1/mp/orders/order_group_datail`, {params: payload})
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/orders/group`,payload)
}

export async function get_text (payload) {

  return api.post(`/api/v1/mp/luohu/wordslots/info`, payload)
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/orders/group`,payload)
}

export async function get_question (payload) {
  return api.get(`/api/v1/mp/orders/forbidden_detail`, {params: payload})
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/orders/group`,payload)
}

export async function pay_order (payload) {
  return api.get(`/api/v1/mp/orders/pay_url`, {params: payload})
  //return api.get(`/api/v1/mp/orders/pay_url`, {params: {order_id: payload}})
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/orders/group`,payload)
}







